// AppFooter.vue
<template>
  <footer>
    <p>&copy; 2024 Samantha's Blog</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
footer {
  text-align: center;
  padding: 20px;
  background: #42b983;
  color: white;
}
</style>
